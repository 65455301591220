
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import your components
import HomePage from './Pages/HomePage';
import TriviaPage from './Pages/TriviaPage';
import CategoriesPage from './Pages/CategoriesPage';
import SubcategoriesPage from './Pages/SubcategoriesPage';
import TriviaCategoriesPage from './Pages/TriviacategoriesPage';

// Define your routes
const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/categories',
        component: CategoriesPage,
    },
    {
        path: '/categories/:slug',
        component: SubcategoriesPage,
    },
    {
        path: '/categories/:subcategory/:slug',
        component: TriviaCategoriesPage,
    },
    {
        path: '/categories/:subcategory/:triviacategory/:slug',
        component: TriviaPage,
    },
];

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={<route.component />}
                    />
                ))}
            </Routes>
        </BrowserRouter>
    );
}
export default Router;
