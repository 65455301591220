import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import CategoriesImage from "../categories_photo.png";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const SubcategoriesPage = () => {
  const [subcategories, setSubcategories] = useState([]);
  const params = useParams();
  const fetchTriviaData = async () => {
    try {
      const response = await axios.post("/api/subcategories_data", {
        subcategory: params.slug,
      });
      const data = await response.data;
      setSubcategories(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTriviaData();
  }, []);

  return (
    <div className="min-h-screen bg-gray-500">
      {/* Navbar */}
      <nav className="bg-gray-800 text-white py-4 px-32 flex justify-between items-center space-x-2">
        <a href="/" className="text-xl font-semibold">
          Home
        </a>
        <div>
          <a href="/categories" className="px-4 hover:underline">
            Categories
          </a>
        </div>
      </nav>

      {/* Trivia Section */}

      <div className="bg-[#271938] rounded shadow-lg overflow-hidden">
        {/* Downloaded background image */}
        <div className="relative z-0 -mt-8 h-[50vh]">
          <img
            className="absolute inset-0 w-full h-full object-cover"
            src={CategoriesImage}
            alt={"Categories Image"}
          />
        </div>

        {/* Trivia Content */}

        <div className="relative z-10 px-32 -mt-8">
          {/* <h1 className="text-3xl font-bold mb-4">Stranger Things All Seasons Trivia Quiz</h1> */}
          <div className="grid gap-8 grid-cols-4 mb-4">
            {/* SIDEBAR */}
            <div className="space-y-4">
              {/* HOW TO PLAY */}
              <div className="bg-[#FF6857] border-2 border-black p-6 text-white rounded-2xl">
                <h2 className="text-xl font-semibold text-center mb-3">
                  Free Trivia Questions
                </h2>
                <ul className="list-disc list-inside space-y-2 text-base">
                  <li> Choose whatever trivia category you want to play</li>
                  <li> Answer all the questions</li>
                  <li> See your score and share it with your friends</li>
                </ul>
              </div>

              {/* PLACEHOLDER FOR ADS */}
              <div className="bg-gray-200 h-[800px] text-black flex justify-center items-center">
                Ads placeholder
              </div>
            </div>
            {/* MAIN AREA */}
            <div className="col-span-3 space-y-4">
              <div className="p-6 text-white border-2 border-black rounded-2xl bg-[#042E4E] text-lg">
                Embark on a journey of intellect and challenge with our
                captivating trivia! Test your knowledge across a diverse range
                of topics and climb the leaderboard of masterminds. Perfect your
                quiz game with question sets designed to entertain, educate, and
                inspire curiosity in trivia lovers of all ages.
              </div>
              {/* TITLE */}
              <h1 className="text-4xl font-bold mb-3 text-center text-white">
                Thousands of Trivia Questions
              </h1>
              <h2 className="text-xl font-semibold text-center mb-3 text-white">
                We have got over{" "}
                <span className="underline underline-offset-4">1000</span>{" "}
                categories of trivia questions with answers.
              </h2>

              {/* Divider */}
              <div className="border-b-2 border-[#FF6857] mb-4"></div>

              {/* CATEGORIES */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {subcategories.map((subcategory) => (
                  <CategoryCard
                    key={subcategory.subcategory_id}
                    image={subcategory.image_name}
                    title={subcategory.subcategory_name}
                    link={subcategory.slug}
                  />
                ))}
              </div>

              {/* Divider */}
            </div>
          </div>
        </div>
        {/* FOOTER */}
        <footer className="bg-[#FF6857] text-white py-8 px-32">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center md:text-left">
            {/* PDF Downloads */}
            <div className="space-y-2">
              <h3 className="text-xl font-semibold mb-3">Download Quiz PDFs</h3>
              <ul>
                <li>
                  <a href="/questions-answers.pdf" className="underline">
                    Questions with answers PDF
                  </a>
                </li>
                <li>
                  <a href="/questions.pdf" className="underline">
                    Questions without answers PDF
                  </a>
                </li>
                <li>
                  <a href="/answers.pdf" className="underline">
                    Answers PDF
                  </a>
                </li>
              </ul>
            </div>

            {/* More Questions */}
            <div className="space-y-2">
              <h3 className="text-xl font-semibold mb-3">More Trivia</h3>
              <p>Do you need more questions?</p>
              <p>
                Head back to our
                <a href="/main-trivia" className="underline">
                  {" "}
                  main trivia page.
                </a>
              </p>
            </div>

            {/* Other Quizzes */}
            <div className="space-y-2">
              <h3 className="text-xl font-semibold mb-3">Other TV Quizzes</h3>
              <ul>
                <li>
                  <a href="/halloween-trivia" className="underline">
                    Halloween Trivia Quiz
                  </a>
                </li>
                <li>
                  <a href="/friends-trivia" className="underline">
                    Friends Trivia Quiz
                  </a>
                </li>
                <li>
                  <a href="/the-office-trivia" className="underline">
                    The Office Trivia Quiz
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Creator and Contact */}
          <div className="border-t-2 border-white mt-4 pt-4">
            <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
              <p className="text-lg font-semibold">
                Created by Mateusz -
                <a
                  href="https://twitter.com/betterMateusz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {" "}
                  Follow on Twitter
                </a>
              </p>

              <p className="text-lg font-semibold">
                Contact us at
                <a
                  href="mailto:contact@732development.com"
                  className="underline"
                >
                  {" "}
                  contact@732development.com
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default SubcategoriesPage;

const CategoryCard = ({ image, title, link }) => {
  const currentUrl = window.location.href;

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      {/* <div
        className="w-full h-40 bg-cover bg-center"
        style={{ backgroundImage: `url(${image})` }}
      ></div> */}
      <div className="p-6">
        <h3 className="text-2xl font-bold mb-4">{title}</h3>
        <div className="flex justify-end">
          <Link
            to={currentUrl + "/" + link}
            className="text-blue-500 hover:underline"
          >
            Explore Now
          </Link>
        </div>
      </div>
    </div>
  );
};
