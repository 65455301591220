import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";

const TriviaPage = () => {

  const [trivia_data, setTriviaData] = useState(null);
  const [answers, setAnswers] = useState({});
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [triviaCategory, setTriviaCategory] = useState(null);
  const param = useParams();

  const fetchTriviaData = async () => {
    try {
      console.log(param);
      const response = await axios.post("/api/trivia_data", {
        name: param.slug,
      });
      const data = await response.data;
      setTriviaData(data.trivia);
      const image = new Image();
      image.src = `data:image/png;base64,${data.image_data}`;
      setImage(image);
      // '/categories/:subcategory/:triviacategory/:slug',

      const subcategory = param.subcategory.replace("_"," ");
      const modifiedSubcategory = subcategory.charAt(0).toUpperCase() + subcategory.slice(1);
      setSubcategory(modifiedSubcategory + " Trivia");

      const triviacategory = param.triviacategory.replace("_"," ");
      const modifiedTriviacategory = triviacategory.charAt(0).toUpperCase() + triviacategory.slice(1);
      setTriviaCategory(modifiedTriviacategory + " Trivia");

      // console.log(data.image_data);
    } catch (error) {
      setTriviaData(null);
      console.log(error);
    }
  };

  const checkAnswers = () => {
    let score = 0;
    Object.entries(answers).map(([questionIndex, answer]) => {
      if (trivia_data.questions[questionIndex].correct_answer === answer) {
        score++;
      }
    });
    alert(`Your score is ${score} out of ${trivia_data.questions.length}`);
  };

  const downloadQuestions = () => {
    const doc = new jsPDF();
    const questions = trivia_data.questions;
    let yPos = 10;
    const pageWidth = doc.internal.pageSize.getWidth();

    questions.forEach((item, index) => {
      const questionText = `${index + 1}. ${item["question_" + (index + 1)]}`;
      const wrappedText = doc.splitTextToSize(questionText, pageWidth - 20); // Adjust the width as needed
      doc.text(10, yPos, wrappedText);
      yPos += 10 * wrappedText.length; // Adjust vertical space based on wrapped text

      Object.entries(item.options).forEach(([key, value]) => {
        const optionText = `${key}: ${value}`;
        const wrappedOptionText = doc.splitTextToSize(
          optionText,
          pageWidth - 35
        ); // Leave space for the square

        // Draw a square for the tick
        doc.rect(10, yPos - 3, 5, 5); // Adjust the size and position of the square as needed

        // Position the text next to the square
        doc.text(20, yPos, wrappedOptionText);
        yPos += 10 * wrappedOptionText.length; // Adjust for wrapped text
      });

      yPos += 10; // Extra space after each question
      if (yPos >= doc.internal.pageSize.getHeight() - 50) {
        doc.addPage();
        yPos = 10;
      }
    });

    doc.save(`${trivia_data.name}_questions.pdf`);
  };

  const downloadAnswers = () => {
    const doc = new jsPDF();
    const questions = trivia_data.questions;
    let yPos = 10;
    const pageWidth = doc.internal.pageSize.getWidth();

    questions.forEach((item, index) => {
      const questionText = `${index + 1}. ${item["question_" + (index + 1)]}`;
      const wrappedText = doc.splitTextToSize(questionText, pageWidth - 20); // Adjust the width as needed
      doc.text(10, yPos, wrappedText);
      yPos += 10 * wrappedText.length; // Adjust vertical space based on wrapped text

      const answerText = `Answer: ${item.correct_answer}`;
      const wrappedAnswerText = doc.splitTextToSize(answerText, pageWidth - 20); // Adjust the width as needed
      doc.text(10, yPos, wrappedAnswerText);
      yPos += 10 * wrappedAnswerText.length; // Adjust vertical space based on wrapped text

      yPos += 10; // Extra space after each question
      if (yPos >= doc.internal.pageSize.getHeight() - 50) {
        doc.addPage();
        yPos = 10;
      }
    });

    doc.save(`${trivia_data.name}_answers.pdf`);
  };

  useEffect(() => {
    fetchTriviaData();
  }, []);

  return (
    <div className="min-h-screen bg-gray-500">
      {/* Navbar */}
      <nav className="bg-gray-800 text-white py-4 px-32 flex justify-between items-center space-x-2">
        <a href="/" className="text-xl font-semibold">
          Home
        </a>
        <div>
          <a href="/categories" className="px-4 hover:underline">
            Categories
          </a>
          <a href="/categories" className="px-4 hover:underline">
            {">"}
          </a>
          {/* '/categories/:subcategory/:triviacategory/:slug', */}
          <a href={`/categories/${param.subcategory}`} className="px-4 hover:underline">
            {subcategory}
          </a>
          <a href="/categories" className="px-4 hover:underline">
            {">"}
          </a>
          <a href={`/categories/${param.subcategories}/${param.triviacategory}`} className="px-4 hover:underline">
            {triviaCategory}
          </a>
        </div>
      </nav>

      {/* Trivia Section */}

      <div className="bg-[#271938] rounded shadow-lg overflow-hidden">
        {/* Downloaded background image */}
        <div className="relative z-0 -mt-8 h-[50vh]">
          {image && (
            <img
              className="absolute inset-0 w-full h-full object-cover"
              src={image.src}
              alt={trivia_data ? trivia_data.image_name : "loading..."}
            />
          )}
        </div>

        {/* Trivia Content */}

        <div className="relative z-10 px-32 -mt-8">
          {/* <h1 className="text-3xl font-bold mb-4">Stranger Things All Seasons Trivia Quiz</h1> */}
          <div className="grid gap-8 grid-cols-4 mb-4">
            {/* SIDEBAR */}
            <div className="space-y-4">
              {/* HOW TO PLAY */}
              <div className="bg-[#FF6857] border-2 border-black p-6 text-white rounded-2xl">
                <h2 className="text-xl font-semibold text-center mb-3">
                  How to play?
                </h2>
                <ul className="list-disc list-inside space-y-2 text-base">
                  <li>
                    {" "}
                    Select Answers: Pick A, B, C, or D for each question.{" "}
                  </li>
                  <li>
                    {" "}
                    Check Answers: Click 'Check my answers' to see your score.{" "}
                  </li>
                  <li>
                    {" "}
                    Download PDF: Get questions with or without answers by
                    clicking 'Download' button.{" "}
                  </li>
                </ul>
              </div>

              {/* DOWNLOAD PDF */}
              <button
                className="w-full bg-[#FF6857] border-2 border-black text-white hover:bg-black py-3 rounded-2xl text-center text-lg font-semibold"
                onClick={() => {
                  downloadQuestions();
                }}
              >
                Download Questions
              </button>
              <button
                className="w-full bg-[#FF6857] border-2 border-black text-white hover:bg-black py-3 rounded-2xl text-center text-lg font-semibold"
                onClick={() => {
                  downloadAnswers();
                }}
              >
                Download Answers
              </button>
              {/* PLACEHOLDER FOR ADS */}
              <div className="bg-gray-200 h-[800px] text-black flex justify-center items-center">
                Ads placeholder
              </div>
            </div>
            {/* MAIN AREA */}
            <div className="col-span-3 space-y-4">
              <div className="p-6 text-white border-2 border-black rounded-2xl bg-[#042E4E] text-lg">
                {trivia_data ? trivia_data.long_description : "loading..."}
              </div>
              {/* TITLE */}
              <h1 className="text-4xl font-bold mb-3 text-center text-white">
                {trivia_data ? trivia_data.name : "loading..."}
              </h1>
              <h2 className="text-xl font-semibold text-center mb-3 text-white">
                This trivia quiz has{" "}
                <span className="underline underline-offset-4">
                  {trivia_data ? trivia_data.questions.length : "loading..."}{" "}
                  questions.
                </span>
              </h2>
              <h3 className="text-lg mb-3 text-center text-white">
                Created on{" "}
                {trivia_data ? trivia_data.date_created : "loading..."} by{" "}
                {trivia_data ? trivia_data.author : "loading..."}
              </h3>
              {/* Divider */}
              <div className="border-b-2 border-[#FF6857] mb-4"></div>
              {/* Questions */}

              <div className="mb-8">
                {trivia_data &&
                  trivia_data.questions.map((item, index) => (
                    <div key={index} className="mb-8">
                      <div className="flex items-start">
                        <div className="mr-8 w-16 h-16 bg-[#FF6857] shadow-lg border-2 border-black rounded-full flex justify-center items-center text-xl font-bold text-white">
                          {index + 1}
                        </div>
                        <div className="flex-1">
                          <h2 className="text-2xl text-white mb-4 font-semibold">
                            {item["question_" + (index + 1)]}
                          </h2>
                          <ul className="grid grid-cols-2 gap-4">
                            {Object.entries(item.options).map(
                              ([optionKey, optionValue], optionIndex) => (
                                <li key={optionKey}>
                                  <button
                                    className={`w-full border-2 ${
                                      answers[index] === optionKey
                                        ? "bg-black"
                                        : "bg-[#FF6857]"
                                    } border-black text-white hover:bg-opacity-75 transition-colors duration-300 py-3 rounded-xl text-lg font-semibold`}
                                    onClick={() => {
                                      setAnswers({
                                        ...answers,
                                        [index]: optionKey,
                                      });
                                    }}
                                  >
                                    {optionValue}
                                  </button>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="border-b-2 border-[#1F477C] my-4"></div>
                    </div>
                  ))}

                <button
                  className="w-full mb-8 bg-[#FF6857] border-2 border-black text-white hover:bg-opacity-75 transition-colors duration-300 py-4 rounded-2xl text-center text-xl font-bold shadow-lg"
                  onClick={() => {
                    checkAnswers();
                  }}
                >
                  Check my answers
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER */}
        <footer className="bg-[#FF6857] text-white py-8 px-32">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center md:text-left">
            {/* PDF Downloads */}
            <div className="space-y-2">
              <h3 className="text-xl font-semibold mb-3">Download Quiz PDFs</h3>
              <ul>
                <li>
                  <a href="/questions-answers.pdf" className="underline">
                    Questions with answers PDF
                  </a>
                </li>
                <li>
                  <a href="/questions.pdf" className="underline">
                    Questions without answers PDF
                  </a>
                </li>
                <li>
                  <a href="/answers.pdf" className="underline">
                    Answers PDF
                  </a>
                </li>
              </ul>
            </div>

            {/* More Questions */}
            <div className="space-y-2">
              <h3 className="text-xl font-semibold mb-3">More Trivia</h3>
              <p>Do you need more questions?</p>
              <p>
                Head back to our
                <a href="/main-trivia" className="underline">
                  {" "}
                  main trivia page.
                </a>
              </p>
            </div>

            {/* Other Quizzes */}
            <div className="space-y-2">
              <h3 className="text-xl font-semibold mb-3">Other TV Quizzes</h3>
              <ul>
                <li>
                  <a href="/halloween-trivia" className="underline">
                    Halloween Trivia Quiz
                  </a>
                </li>
                <li>
                  <a href="/friends-trivia" className="underline">
                    Friends Trivia Quiz
                  </a>
                </li>
                <li>
                  <a href="/the-office-trivia" className="underline">
                    The Office Trivia Quiz
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Creator and Contact */}
          <div className="border-t-2 border-white mt-4 pt-4">
            <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
              <p className="text-lg font-semibold">
                Created by Mateusz -
                <a
                  href="https://twitter.com/betterMateusz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {" "}
                  Follow on Twitter
                </a>
              </p>

              <p className="text-lg font-semibold">
                Contact us at
                <a
                  href="mailto:contact@732development.com"
                  className="underline"
                >
                  {" "}
                  contact@732development.com
                </a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default TriviaPage;
