import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#FF6857] text-white py-8 px-32">
      <div className=" text-center md:text-left">
        <Link to="/" className="text-xl font-semibold">
          Join the Fun!
        </Link>
        <p className="mt-4">
          Thank you for visiting our website. We hope you enjoy playing our
          trivia games!
        </p>
      </div>

      {/* Creator and Contact */}
      <div className="border-t-2 border-white mt-4 pt-4">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="space-y-2">
            <p className="text-lg font-semibold">
              Created by 👋 Mateusz -{" "}
              <a
                href="https://twitter.com/betterMateusz"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Follow on Twitter
              </a>
            </p>
            <p className="mb-4">
              &copy; {currentYear} 732 Development - All rights reserved
            </p>
          </div>
          <div className="space-y-2">
            <p className="text-lg font-semibold">
              Contact us at{" "}
              <a href="mailto:contact@732development.com" className="underline">
                contact@732development.com
              </a>
            </p>
            <nav aria-label="Secondary">
              <Link
                to="/"
                className="text-white hover:text-blue-500 transition duration-300"
              >
                Privacy Policy
              </Link>
              <Link
                to="/"
                className="ml-4 text-white hover:text-blue-500 transition duration-300"
              >
                Terms of Service
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
