import React from "react";
import HeroImage from "../hero_photo.png";
import ScienceImage from "../science_and_nature.png";
import HistoryImage from "../history_and_geography.png";
import PopCultureImage from "../pop_culture.png";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";

const HomePage = () => {

  return (
    <main className="min-h-screen bg-[#271938] " role="main">
      {/* Navbar */}
      <header className="bg-gray-800 text-white py-4 px-8 lg:px-32 flex justify-between items-center">
        <Link to="/" className="text-xl font-semibold" aria-label="Home">
          Join Trivia!
        </Link>
        <nav aria-label="Primary">
          <Link
            to="/categories"
            className="px-4 hover:underline"
            aria-label="Categories"
          >
            Categories
          </Link>
          <Link to="/about" className="px-4 hover:underline" aria-label="About">
            About
          </Link>
          <a
            href="mailto:mailto:contact@732development.com"
            className="px-4 hover:underline"
            aria-label="Contact"
          >
            Contact
          </a>
        </nav>
      </header>

      {/* Hero Section */}
      <section
        className="rounded shadow-lg overflow-hidden"
        aria-labelledby="main-heading"
      >
        <div
          className="bg-cover bg-no-repeat bg-center h-[50vh]"
          style={{ backgroundImage: `url(${HeroImage})` }}
        ></div>
        <div className="text-center px-8 lg:px-20 -mt-16">
          <div className="border-2 border-gray-700 rounded-2xl bg-[#042E4E] p-8">
            <h1
              id="main-heading"
              className="text-6xl text-white mb-4 font-bold"
            >
              Completely <span className="underline">FREE</span> Trivia
            </h1>
            <p className="text-2xl text-white mb-6">
            You don't need an account to unleash your inner genius and test your knowledge with our exciting trivia games
            </p>
            <div className="mb-4">
              <Link
                to="/categories"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded transition duration-300"
              >
                Play Now - It's Free!
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Feature Section */}
      <section className="py-12">
        <div className="container mx-auto px-6 lg:px-24">
          <h2 className="text-4xl text-white font-bold text-center mb-8">
            Explore Diverse Trivia Categories
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            <CategoryCard
              image={ScienceImage}
              title="Science and Nature"
              link="/categories/science"
            />
            <CategoryCard
              image={HistoryImage}
              title="History and Geography"
              link="/categories/history"
            />
            <CategoryCard
              image={PopCultureImage}
              title="Pop Culture"
              link="/categories/pop-culture"
            />
          </div>
          <div className="text-center  mb-4">
              <Link
                to="/categories"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded transition duration-300"
              >
                Categories
              </Link>
            </div>
          
        </div>
     
      </section>

      {/* How to Play Section */}
      <section className="py-12">
        <div className="container mx-auto px-6 lg:px-24">
          <h2 className="text-white text-4xl font-bold text-center mb-8">
            How to Play? It's Easy!
          </h2>
          <div className="flex flex-wrap justify-center items-start">
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 bg-white">
                <span className="text-xl font-bold text-blue-500">
                  1. Select Trivia
                </span>
                <p className="mt-2">
                  Choose one of our free trivia and simply click play.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 bg-white">
                <span className="text-xl font-bold text-blue-500">
                  2. Select Answers
                </span>
                <p className="mt-2">
                  Choose the best answer for each trivia question from the
                  options provided.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 bg-white">
                <span className="text-xl font-bold text-blue-500">
                  3. Submit Answers
                </span>
                <p className="mt-2">
                  Submit your answers to see your score. Share your results and
                  challenge friends!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-12  text-white">
        <div className="container mx-auto px-6 lg:px-32">
          <h2 className="text-4xl font-bold text-center mb-8">
            What Players Are Saying
          </h2>
          <div className="flex flex-wrap justify-center items-center">
            <blockquote className="rounded-lg shadow-lg p-6 bg-gray-700 text-center w-full md:w-1/2 lg:w-1/3 m-4">
              <p>
                "Trivia games have never been this fun and educational - perfect
                for quick learning breaks!"
              </p>
              <cite>- John Smith, Trivia Enthusiast</cite>
            </blockquote>
            <blockquote className="rounded-lg shadow-lg p-6 bg-gray-700 text-center w-full md:w-1/2 lg:w-1/3 m-4">
              <p>
                "The range of categories is fantastic - there's a new trivia
                challenge every day!"
              </p>
              <cite>- Jane Doe, Trivia Fanatic</cite>
            </blockquote>
            <blockquote className="rounded-lg shadow-lg p-6 bg-gray-700 text-center w-full md:w-1/2 lg:w-1/3 m-4">
              <p>
                "As a long-time trivia player, this is by far the best trivia
                experience I've had. Totally addictive!"
              </p>
              <cite>- Alex Johnson, Trivia Expert</cite>
            </blockquote>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-12">
        <div className="container mx-auto px-6 lg:px-24">
          <h2 className="text-white text-4xl font-bold text-center mb-8">
            Frequently Asked Questions
          </h2>
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 bg-white">
                <span className="text-xl font-bold text-blue-500">
                  How do I play?
                </span>
                <p className="mt-2">
                  Choose a category and answer the trivia questions. Submit your
                  answers to see your score!
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 bg-white">
                <span className="text-xl font-bold text-blue-500">
                  How many questions are there?
                </span>
                <p className="mt-2">
                  Each trivia game consists of 10 questions. You can play as
                  many games as you like!
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-4">
              <div className="rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300 bg-white">
                <span className="text-xl font-bold text-blue-500">
                  How do I share my results?
                </span>
                <p className="mt-2">
                  After submitting your answers, you will be able to share your
                  results on social media.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default HomePage;

const CategoryCard = ({ image, title, link }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <div
      className="w-full h-40 bg-cover bg-center"
      style={{ backgroundImage: `url(${image})` }}
    ></div>
    <div className="p-6">
      <h3 className="text-2xl font-bold mb-4">{title}</h3>
      <div className="flex justify-end">
        <Link to={link} className="text-blue-500 hover:underline">
          Explore Now
        </Link>
      </div>
    </div>
  </div>
);
